<template>
  <div>
    <BaseHeaderBar title="Rincian" :showCustomerService="false" :showBackButton="true" color="#333" bgColor="#FFF" />
    <div class="top-block">
      <p><span></span>{{ repaymentDetail.badrnkName }}（{{ repaymentDetail.badrnkAccount }}）</p>
      <p>Jumlah pinjaman sudah ditransfer ke rekening bank ini</p>
      <p>Nama Pengguna: {{ repaymentDetail.cudrstomerName }}</p>
    </div>
    <div class="detail-list">
      <div>
        <p>Tanggal pinjaman</p>
        <p>{{ repaymentDetail.ardrrivalTime }}</p>
      </div>
      <div>
        <p>Jumlah pinjaman</p>
        <p>Rp{{ repaymentDetail.lodranAmount }}</p>
      </div>
      <div>
        <p>Lama pinjaman</p>
        <p>{{ repaymentDetail.tidrmers }}</p>
      </div>
      <div>
        <p>Jumlah keterlambatan</p>
        <p>{{ repaymentDetail.ovdrerdueDay }} Hari</p>
      </div>
      <div>
        <p>Denda keterlambatan</p>
        <p>Rp{{ repaymentDetail.ovdrerdueInterestpenalty }}</p>
      </div>
      <div>
        <p>Biaya penundaan</p>
        <p>Rp{{ repaymentDetail.ovdrerdueLiquidateddamages }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
import webviewBack from '@/mixins/webviewBack'

export default {
  name: 'RepaymenDetail',
  mixins: [webviewBack],
  data () {
    return {
      repaymentDetail: {}
    }
  },
  created () {
    this.getRepaymentDetail()
  },
  methods: {
    getRepaymentDetail () {
      request.post('homeDRInterface')
        .then(res => {
          this.repaymentDetail = res.bidrllDetails
        })
    }
  }
}
</script>

<style scoped lang="less">
.top-block {
  padding: 32px 0 32px 30px;
  border-radius: 1px;
  background-color:  @theme-color;

  p {
    display: flex;
    align-items: center;
    font-size: 26px;
    color: #FFF;

    &:nth-child(n+2) {
      margin-top: 24px;
    }

    span {
      width: 40px;
      height: 29px;
      margin-right: 16px;
      background-color: #FFF;
    }
  }
}

.detail-list {
  margin: 24px 30px 0;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 88px;
    line-height: 88px;
    padding-left: 24px;
    padding-right: 24px;

    &:nth-child(n+2) {
      margin-top: 6px;
    }

    &:nth-child(odd) {
      background-color: @body-bg-color;
    }

    p {
      font-size: 26px;
      color: #333;
    }
  }
}
</style>
